.App {
  text-align: center;
  font-family: 'Bebas Neue', cursive;  -webkit-font-smoothing: antialiased;
  color:white;
  font-size: larger;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-index{
  text-align: left;
  background-color:"blue";
  width: 100%;
  display: inherit;
  justify-content: inherit;
  text-align: left;
}

.menu-index a{
  color: white;
  text-decoration: none;
}

.menu-index a:hover{
  color: lightgray;
}

.menu-index ul li{
  font-size: larger;
  list-style-type: none;
  text-align: center;
}

.menu-index ul li li{
  font-size: large;
  font-family:initial;
  list-style-type: disc;
  text-align: left;
}

.main-content{
  font-family:initial;
  padding-left:10%;
  padding-right:10%;
}

@media only screen and (max-width :600px){
  .main-content{
    padding-left: 0%;
    padding-right: 0%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to { 
    transform: rotate(360deg);
  }
}

/* latin-ext */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXoo9Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXoo9Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}